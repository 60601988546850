import { nanoid } from 'nanoid';

/**
 * mapAdvancedStandings
 * @param {array} advancedStandings
 * @returns {object} formatted advanced standings (Year) object
 */
const mapAdvancedStandings = (advancedStandings = null) => {
  if (!Array.isArray(advancedStandings) || !advancedStandings.length) {
    console.error(
      'Invalid or empty advancedStandings data:',
      advancedStandings
    );
    return null;
  }
  return {
    periods: [
      {
        id: nanoid(),
        blocks: [...advancedStandings],
      },
    ],
    id: nanoid(),
    name: 'Credit',
  };
};

export default mapAdvancedStandings;
