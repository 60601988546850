import React, { useState } from 'react';
import { Button, Alert } from '@monash/portal-react';
import c from './info-item.module.scss';

const InfoItem = ({
  icon: Icon,
  form: Form,
  name,
  displayData,
  error,
  action,
}) => {
  const [inEdit, setInEdit] = useState(false);

  return (
    <div className={c.infoItem}>
      <Icon />
      {!inEdit && (
        <div className={c.content}>
          <label>{name}</label>
          {!error && <p>{displayData}</p>}
          {error && <Alert>Currently unavailable</Alert>}
        </div>
      )}
      {Form && !error && !inEdit && (
        <Button
          type="text"
          ariaLabel="Edit Pronouns"
          size="small"
          onClick={() => setInEdit(true)}
        >
          Edit
        </Button>
      )}
      {Form && !error && inEdit && (
        <Form inEdit={inEdit} setInEdit={setInEdit} />
      )}

      {!error && action}
    </div>
  );
};

export default InfoItem;
