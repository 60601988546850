import { MenuContext } from '@monash/portal-frontend-common';
import React, { useContext } from 'react';
import c from './bar-item.module.scss';

const BarItem = ({ pctAgainstMaxCP, background, firstItem, zIndex }) => {
  const { size } = useContext(MenuContext);
  // offset with margin and width to create the overlapping look
  const offsetPct = size === 'S' ? 4 : 2;

  return (
    <div
      className={c.barItem}
      style={{
        zIndex: `${zIndex}`,
        marginLeft: `${!firstItem && -offsetPct}%`,
        width: `${firstItem ? pctAgainstMaxCP : pctAgainstMaxCP + offsetPct}%`,
      }}
    >
      <div className={c.filled} style={{ background: `${background}` }} />
    </div>
  );
};

export default BarItem;
