import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-credit-points-tag.module.scss';

const BlockCreditPointsTag = ({ creditPoints }) => {
  return (
    <div className={c.blockCreditPointsTag}>
      <VirtuallyHidden element="label" text="Credit points:" />
      {`${creditPoints} CP`}
    </div>
  );
};

export default BlockCreditPointsTag;
