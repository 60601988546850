import React from 'react';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-description.module.scss';

const BlockDescription = ({ description }) => {
  return (
    <p className={c.blockDescription}>
      <VirtuallyHidden element="label" text="Unit description:" />
      {capitaliseFirstWord(description)}
    </p>
  );
};

export default BlockDescription;
