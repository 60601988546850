import { capitaliseFirstWord } from '@monash/portal-frontend-common';

/**
 * getBlockDescription
 * @param {object} block - block data object
 * @returns {undefined|string} - processed block desc string
 */
export const getBlockDescription = (block = { blockType: '' }) => {
  let description;

  switch (block.blockType) {
    case 'UNIT':
      description = block.description;
      break;
    case 'CREDIT':
      description =
        block.description ||
        `Level ${block.unitLevel} ${capitaliseFirstWord(
          block.disciplineDescription
        )}`;
      break;
    default:
  }

  return description;
};
