import { Icon } from '@monash/portal-react';
import React, { useContext } from 'react';
import Card from '../../../ui/card/Card';
import InfoItem from '../info-item/InfoItem';
import { capitaliseAllWords } from '@monash/portal-frontend-common';
import { StudentContext } from '../../../providers/StudentProvider';

const joinAddressLines = (address) => {
  if (!address) return null;
  const joined = Object.values(address)
    .filter((v) => v)
    .map((word) => capitaliseAllWords(word.toLowerCase()))
    .join(', ');
  return joined;
};

const Contact = () => {
  const { profileData } = useContext(StudentContext);

  const infoList = [
    {
      name: 'Postal address',
      icon: Icon.MapPin,
      data: joinAddressLines(profileData?.postalAddress),
    },
    {
      name: 'Home address',
      icon: Icon.Home,
      data: joinAddressLines(profileData?.homeAddress),
    },
    {
      name: 'Mailing name format',
      data: profileData?.mailingNameFormat,
      icon: Icon.LayoutCards,
    },
    {
      name: 'Mobile phone number',
      data: profileData?.mobilePhoneNumber,
      icon: Icon.Phone,
    },
    {
      name: 'Home phone number',
      data: profileData?.homePhoneNumber,
      icon: Icon.Phone,
    },
    // {
    //   name: 'Emergency contact',
    //   data: `${profileData?.emergencyContact[0].name} - ${profileData?.emergencyContact[0].relationship}`,
    //   icon: Icon.UserExclamation,
    // },
  ];

  return (
    <Card>
      {infoList.map(
        (item, i) =>
          item.data && (
            <InfoItem
              name={item.name}
              icon={item.icon}
              form={item.form}
              displayData={item.data}
              key={i}
            />
          )
      )}
    </Card>
  );
};

export default Contact;
