import React from 'react';
import c from './planning.module.scss';
import { Illustration } from '@monash/portal-react';

const Planning = () => {
  return (
    <div className={c.plan}>
      <div className={c.crane}>
        <Illustration.LoadingCrane />
      </div>
      <div className={c.string} />
      <div className={c.unit1} />
      <div className={c.plan}>
        <Illustration.LoadingPlan />
      </div>
      <div className={c.ladder}>
        <Illustration.LoadingLadder />
      </div>
      <div className={c.unit2} />
      <div className={c.person1Top}>
        <Illustration.LoadingPerson1Top />
      </div>
      <div className={c.person1Bottom}>
        <Illustration.LoadingPerson1Bottom />
      </div>
      <div className={c.shadow} />

      <div className={c.unit3}>
        <div className={c.person2}>
          <Illustration.LoadingPerson2 />
          <div className={c.person2Arm}>
            <Illustration.LoadingPerson2Arm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Planning;
