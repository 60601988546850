import { VirtuallyHidden } from '@monash/portal-react';
import c from './grid-block.module.scss';
import BlockCreditPointsTag from '../../block-components/block-credit-points-tag/BlockCreditPointsTag';
import BlockStatusTag from '../../block-components/block-status-tag/BlockStatusTag';
import { getBlockStatusProps } from '../../../../../utilities/functions/get-block-status-props';
import { getBlockDescription } from '../../../../../utilities/functions/get-block-description';
import { useContext } from 'react';
import BlockUnitCode from '../../block-components/block-unit-code/BlockUnitCode';
import BlockDescription from '../../block-components/block-description/BlockDescription';
import BlockGrade from '../../block-components/block-grade/BlockGrade';
import { MenuContext } from '@monash/portal-frontend-common';

const getBlockColumnSpan = (cp, size) => {
  if (size === 'S') {
    if (cp > 6) {
      return 2;
    } else {
      return 1;
    }
  }
  if (size === 'L' || size === 'M') {
    if (cp > 0 && cp <= 24) {
      return Math.floor(cp / 6);
    } else if (cp > 24) {
      return 4;
    } else {
      return 1;
    }
  }
};

const GridBlock = ({ block }) => {
  const blockStatusProps = getBlockStatusProps(block);
  const description = getBlockDescription(block);
  const { size } = useContext(MenuContext);

  return (
    <div
      tabIndex="0"
      className={c.gridBlock}
      style={{
        gridColumn: `auto / span ${getBlockColumnSpan(
          block.creditPoints,
          size
        )}`,
      }}
    >
      <VirtuallyHidden element="label" text="Unit card:" />
      <div className={c.top}>
        {/* unit code */}
        {block.name && <BlockUnitCode code={block.name} />}
        {/* description */}
        <div className={c.title}>
          <BlockDescription description={description} />
        </div>
        {/* credits */}
        <BlockCreditPointsTag creditPoints={block.creditPoints || 0} />
      </div>

      {blockStatusProps && (
        <div className={c.bottom}>
          {/* enrolment status */}
          <BlockStatusTag blob={false} status={blockStatusProps} />
          {/* grade */}
          {block.mark && <BlockGrade mark={block.mark} grade={block.grade} />}
        </div>
      )}
    </div>
  );
};

export default GridBlock;
