import { Button, Icon } from '@monash/portal-react';
import React, { useRef } from 'react';
import c from './block-label.module.scss';

const BlockLabel = ({
  label,
  guide = false,
  openGuide,
  firstBlockInAPeriod,
}) => {
  const guideRef = useRef();

  return (
    <div
      className={`${c.blockLabel} ${
        !firstBlockInAPeriod ? c.hiddenInPrint : ''
      }`}
    >
      <label aria-hidden="true">{label}</label>
      {guide && (
        <Button
          ref={guideRef}
          onClick={() => openGuide(guideRef)}
          type="text"
          ariaLabel="Open glossary"
          ariaHaspopup="true"
          iconPosition="right"
          icon={<Icon.InfoCircle size={20} />}
        />
      )}
    </div>
  );
};

export default BlockLabel;
