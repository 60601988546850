import React from 'react';
import c from './embargo.module.scss';
import encumbranceImageUrl from '../../../../assets/images/encumbrance.svg';

const Embargo = () => {
  return (
    <section className={c.embargo} aria-label="Embargo">
      <h2>Your unofficial academic record is temporarily unavailable</h2>
      <p>
        Sorry, your unofficial academic record is temporarily unavailable while
        results are being prepared.
      </p>

      <img src={encumbranceImageUrl} alt="Ducks crossing the street" />
    </section>
  );
};

export default Embargo;
