import React from 'react';
import c from './legend-item.module.scss';

const LegendItem = ({ background, label, CP }) => {
  return (
    <div className={c.legendItem}>
      {/* TODO: deal with required marker in a more elegant way */}
      <div
        className={`${c.blob} ${label === 'Required' && c.required}`}
        style={{
          background: `${background}`,
        }}
      />
      <span>
        {label}: {CP} CP
      </span>
    </div>
  );
};

export default LegendItem;
