import PageContainer from '../../ui/page-container/PageContainer';
import UnitsAndResults from './units-and-results/UnitsAndResults';
import { useContext, useEffect, useRef, useState } from 'react';
import c from './course-progress.module.scss';
import {
  Loading,
  LoadingIndicator,
  Modal,
  ModalSection,
} from '@monash/portal-react';
import { StudentContext } from '../../providers/StudentProvider';
import QuickLinks from './quick-links/QuickLinks';
import Guide from './units-and-results/guide/Guide';
import FAQs from './faqs/FAQs';
import Overview from './overview/Overview';
import CourseHeader from './course-header/CourseHeader';
import {
  MenuContext,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import Encumbrance from './encumbrance/Encumbrance';
import Embargo from './embargo/Embargo';
import Section from '../../ui/section/Section';

const CourseProgress = () => {
  const { size } = useContext(MenuContext);
  const { enrolments, loadingEnrolments } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const isUnitResultsEmpty =
    selectedCourse &&
    Object.values(selectedCourse?.years).length === 0 &&
    selectedCourse?.advancedStandings.length === 0;

  // Guide modal
  const [modal, setModal] = useState(false);
  const lastOpenedGuideRef = useRef();
  const openGuideModal = (ref) => {
    setModal(true);
    lastOpenedGuideRef.current = ref.current;
  };
  const closeGuideModal = () => {
    setModal(false);
    lastOpenedGuideRef.current.focus();
  };

  useEffect(() => {
    if (!loadingEnrolments && enrolments) {
      setSelectedCourse(enrolments[0]);
    }
  }, [loadingEnrolments, enrolments]);

  // Check any blocks
  const checkBlocks = () => {
    if (currentUser.embargo) {
      return <Embargo />;
    } else if (currentUser.hasEncumbrances) {
      return <Encumbrance />;
    } else {
      return null;
    }
  };
  return (
    <div className={c.courseProgress}>
      <Loading
        isLoading={loadingEnrolments}
        loadingMsg="Loading course progress"
        loadingPage={
          // TODO: refactor and use page container component instead
          // TODO: update loading indicator component and pass message in as a prop
          <div className={c.loadingScreen}>
            <LoadingIndicator />
            <p>Loading course progress</p>
          </div>
        }
      >
        <PageContainer>
          <Modal open={modal} onClose={closeGuideModal} size={size}>
            <ModalSection title="Glossary" onClose={closeGuideModal}>
              <Guide />
            </ModalSection>
          </Modal>

          {checkBlocks()}

          {!checkBlocks() && selectedCourse && (
            <>
              <CourseHeader
                enrolments={enrolments}
                loadingEnrolments={loadingEnrolments}
                selectedCourse={selectedCourse}
                setSelectedCourse={setSelectedCourse}
              />
              <Overview selectedCourse={selectedCourse} />
              {!isUnitResultsEmpty && (
                <UnitsAndResults
                  years={selectedCourse?.years}
                  priorStudy={selectedCourse?.advancedStandings}
                  defaultViewOptionIndex={0}
                  openGuideModal={openGuideModal}
                  earliestFirst={false}
                />
              )}
            </>
          )}
          <QuickLinks />
          <FAQs />
          <div className={c.printOnlyGlossary}>
            <Section title="Glossary">
              <Guide />
            </Section>
          </div>
        </PageContainer>
      </Loading>
    </div>
  );
};

export default CourseProgress;
