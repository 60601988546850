import React from 'react';
import c from './course-plan-preview.module.scss';
import Year from '../../course-progress/units-and-results/year/Year';
import { Link, VirtuallyHidden } from '@monash/portal-react';

const CoursePlanPreview = ({ coursePlan }) => {
  const lastUpdated = new Date(coursePlan.lastUpdated);

  return (
    <div className={c.coursePlanPreview}>
      <p>Your course plan:</p>

      <Link to="/course-planning-beta/course-plan" data={coursePlan}>
        <VirtuallyHidden text="Navigate to course plan" />
        <div className={c.previewCard}>
          <div className={c.snapshot} aria-hidden="true" inert="">
            <Year
              year={coursePlan.years['2024']}
              listView={false}
              earliestToLatest={true}
              sticky={false}
            />
          </div>
          <div className={c.details}>
            <div>
              <label>Course</label>
              <p>{`${coursePlan.code} \u2013 ${coursePlan.title}`}</p>
            </div>
            <div>
              <label>Last updated</label>
              <p>{lastUpdated.toLocaleDateString().replace(/\//g, '-')}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CoursePlanPreview;
