import React from 'react';
import LegendItem from './legend-item/LegendItem';
import c from './legend.module.scss';

const Legend = ({ barCreditsItems, showRequiredCP, requiredCP }) => {
  return (
    <div className={c.legend}>
      {barCreditsItems.map(
        (item, i) =>
          item.CP > 0 && (
            <LegendItem
              background={item.background}
              label={item.label}
              CP={item.CP}
              key={i}
            />
          )
      )}
      {showRequiredCP && <LegendItem label="Required" CP={requiredCP} />}
    </div>
  );
};

export default Legend;
