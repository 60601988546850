import React from 'react';
import { DropdownWithValue, VirtuallyHidden } from '@monash/portal-react';
import c from './course-selector.module.scss';

const CourseSelector = ({ selectedCourse, setSelectedCourse, enrolments }) => {
  const selectEnrolmentByID = (courseID) =>
    enrolments?.find((enrolment) => enrolment.id === courseID);

  return (
    <div className={c.courseSelector}>
      <VirtuallyHidden element="label" text="course" id="course" />
      <DropdownWithValue
        listId="course list"
        ariaLabelledby="course"
        value={selectedCourse.id}
        onChange={(courseID) =>
          setSelectedCourse(selectEnrolmentByID(courseID))
        }
        data={enrolments?.map((enrolment) => {
          return {
            value: enrolment.id,
            id: enrolment.id,
            text: `${enrolment.code}.${enrolment.version} \u2013 ${enrolment.title}`,
          };
        })}
      />
    </div>
  );
};

export default CourseSelector;
