import React from 'react';
import { Faq } from '@monash/portal-frontend-common';
import Section from '../../../ui/section/Section';
import c from './faq.module.scss';

const relatedFAQsIds = [
  'FAQ-2702',
  'FAQ-2688',
  'FAQ-2860',
  'FAQ-9943',
  'FAQ-2870',
  'FAQ-3530',
  'FAQ-2793',
  'FAQ-2786',
  'FAQ-2662',
  'FAQ-2493',
];

const FAQs = () => {
  return (
    <div className={c.faq}>
      <Section title="Related FAQs">
        <Faq faqIds={relatedFAQsIds} />
      </Section>
    </div>
  );
};

export default FAQs;
