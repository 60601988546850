import React from 'react';
import c from './page-container.module.scss';

const PageContainer = ({
  children,
  type = 'default',
  contentMaxWidth = 1100,
}) => (
  <div className={[c.pageContainer, c[type]].join(' ')}>
    <main
      id="contentContainer"
      className={c.contentContainer}
      style={{ maxWidth: `${contentMaxWidth}px` }}
    >
      {children}
    </main>
  </div>
);

export default PageContainer;
