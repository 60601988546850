import React from 'react';
import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-grade.module.scss';

const BlockGrade = ({ mark, grade }) => {
  return (
    <div className={c.blockGrade}>
      <VirtuallyHidden element="label" text="Unit grade:" />
      {mark} ({grade})
    </div>
  );
};

export default BlockGrade;
