import './App.scss';
import React, { useContext, useEffect, useState } from 'react';
import {
  AppLayout,
  FourOhFour,
  Link,
  ExternalLink,
  Route,
  Icon,
  Sidebar,
  SidebarItem,
  SidebarSection,
  Topnav,
  SkipToContent,
  SkipToContentItem,
} from '@monash/portal-react';
import {
  AppLauncherPad,
  EncumbranceNotification,
  ErrorPageWrapper,
  MenuContext,
  ImpersonationContext,
  UserMenu,
  fsGetDoc,
} from '@monash/portal-frontend-common';
import PreviewPanel from './components/ui/preview-panel/PreviewPanel';
import Profile from './components/pages/profile/Profile';
import CourseProgress from './components/pages/course-progress/CourseProgress';
import { StudentContext } from './components/providers/StudentProvider';
import PageNotFoundWrapper from './components/ui/page-not-found-wrapper/PageNotFoundWrapper';
import CoursePlanning from './components/pages/course-planning/CoursePlanning';
import CoursePlan from './components/pages/course-plan/CoursePlan';

const App = () => {
  const { menu, handleMenu, closeMenu, size, menuRef, getMenuRef } =
    useContext(MenuContext);
  const [showSidebar, setShowSidebar] = useState(true);
  const { fullPageError, enrolments } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { avatar, profileData } = useContext(StudentContext);

  // Nav AX
  const paths = ['/', '/course-progress'];
  const [selectedPageIndex, setSelectedPageIndex] = useState(
    paths.indexOf(window.location.pathname)
  );

  const [ECWhitelisted, setECWhitelisted] = useState(false);
  useEffect(() => {
    const checkWhitelistEC = async () => {
      const docSnap = await fsGetDoc('EC-whitelist/student-ids');

      if (docSnap?.exists()) {
        const data = docSnap.data();
        setECWhitelisted(data.whitelist.includes(currentUser?.studentId));
      } else {
        setECWhitelisted(false);
      }
    };

    if (enrolments) {
      const enrolled = enrolments.filter(
        (e) => e.status === 'ENROLLED' && e.code === 'B2000'
      );
      if (enrolled.length > 0) checkWhitelistEC();
    }
  }, [currentUser, enrolments]);

  return (
    <ErrorPageWrapper
      error={fullPageError}
      navTitle="Profile"
      userMenu={<UserMenu avatarData={avatar} profileData={profileData} />}
      title="Whoops, something went wrong ..."
      message={
        "It looks like the page you're trying to reach is on a study break. But we're working on getting it back and running as soon as we can."
      }
      action={
        <ExternalLink
          type="primary"
          link="https://my.monash.edu.au/wes/enrolment"
          text="Go to WES"
        />
      }
    >
      <SkipToContent>
        <SkipToContentItem text="Top navigation" skipTo="#topNav" />
        <SkipToContentItem text="Sidebar" skipTo="#sidebar" />
        <SkipToContentItem text="Content" skipTo="#contentContainer" />
      </SkipToContent>

      <AppLayout
        topnav={
          <Topnav
            title="Profile"
            onMenu={handleMenu}
            size={size}
            getMenuRef={getMenuRef}
          >
            <div>
              <AppLauncherPad />
              <UserMenu avatarData={avatar} profileData={profileData} />
            </div>
          </Topnav>
        }
        sidebar={
          <Sidebar
            title="Profile"
            ifMenu={menu}
            toggleMenu={handleMenu}
            closeMenu={closeMenu}
            size={size}
            menuRef={menuRef}
            selected={selectedPageIndex}
            onChange={setSelectedPageIndex}
          >
            <SidebarSection title="Tabs">
              <SidebarItem
                id="tab-home"
                linkTo="/"
                text="Profile"
                icon={<Icon.Home />}
                onMenu={closeMenu}
              />
              <SidebarItem
                id="tab-course-progress"
                linkTo="/course-progress"
                text="Course progress"
                icon={<Icon.ListCheck />}
                onMenu={closeMenu}
              />
              {ECWhitelisted && (
                <SidebarItem
                  id="tab-course-planning"
                  linkTo="/course-planning-beta"
                  text="Course planning (beta)"
                  icon={<Icon.TestPipe />}
                  onMenu={closeMenu}
                />
              )}
            </SidebarSection>
          </Sidebar>
        }
        content={
          <>
            {currentUser.hasEncumbrances && <EncumbranceNotification />}

            <Route to="/" exact={true}>
              <Profile />
            </Route>

            <Route to="/course-progress" exact={true}>
              <CourseProgress />
            </Route>

            {ECWhitelisted && (
              <>
                <Route to="/course-planning-beta" exact={true}>
                  <CoursePlanning />
                </Route>
              </>
            )}

            <Route to="/course-planning-beta/course-plan" exact={true}>
              <CoursePlan />
            </Route>

            <Route to="/preview">
              <Profile />
            </Route>

            <PreviewPanel />

            <FourOhFour>
              <PageNotFoundWrapper
                setShowSidebar={setShowSidebar}
                action={<Link to="/">Go to Profile</Link>}
              />
            </FourOhFour>
          </>
        }
        showSidebar={showSidebar}
      />
    </ErrorPageWrapper>
  );
};

export default App;
