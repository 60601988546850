export const getDetailsColumnCount = (size, itemCount) => {
  switch (size) {
    case 'L':
      return itemCount;
    case 'M':
      return 4;
    default:
      return 2;
  }
};
