import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-unit-code.module.scss';

const BlockUnitCode = ({ code }) => {
  return (
    <span className={c.blockUnitCode}>
      <VirtuallyHidden element="label" text="Unit code:" />
      <abbr title={code.split('').join(' ')}>{code}</abbr>
    </span>
  );
};

export default BlockUnitCode;
