/**
 * formatAmount
 * @param {number} amount
 * @param {object} options
 * @returns {undefined|string} formatted number string
 */
export const formatAmount = (amount, options = {}) => {
  const { hideEmptyValues = false } = options;
  if (hideEmptyValues && !amount) {
    return;
  }

  return (amount || 0).toFixed(2);
};
