import { useContext, useEffect, useState } from 'react';
import { ExternalLink, Loading, LoadingIndicator } from '@monash/portal-react';
import c from './course-planning.module.scss';
import Planning from './planning/Planning';
import { StudentContext } from '../../providers/StudentProvider';
import { compress } from './compression';
import { ImpersonationContext, fsGetDoc } from '@monash/portal-frontend-common';
import { formatECPayload } from './utils';
import CoursePlanPreview from './course-plan-preview/CoursePlanPreview';

const CoursePlanning = () => {
  const { enrolments } = useContext(StudentContext);
  const { currentUser } = useContext(ImpersonationContext);
  const [coursePlan, setCoursePlan] = useState(false);
  const [isEnrichingPlan, setIsEnrichingPlan] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    /*
      In order to send data to EC, we need to pass the students data in a
      specific format, via a query parameter (cant use API as they are external),
      so it needs to be small enough to meet browser restrictions 
      (max ~2000 characters).
      This method: 
        - reformat into agreed EC schema
        - zip payload into byte array (make smaller)
        - convert array to hex based string (make even smaller and decompressible)
    */
    if (enrolments) {
      // re-arrange into EC format
      const payload = formatECPayload(enrolments, currentUser.uid);
      // make this as small as possible to try and fit into url
      const compressed = compress(payload);
      const baseUrl =
        process.env.REACT_APP_ENV === 'prod' ? 'enrol' : 'enrol-qat';
      setUrl(`https://${baseUrl}.apps.monash.edu/?d=${compressed}`);
    }
  }, [enrolments]);

  useEffect(() => {
    const getPreviousPlan = async () => {
      const docSnap = await fsGetDoc(`EC-plans/${currentUser.uid}`);
      if (docSnap?.exists()) {
        const data = docSnap.data();
        setCoursePlan(data);
      }
    };
    setIsEnrichingPlan(true);
    getPreviousPlan().then(() => setIsEnrichingPlan(false));
  }, []);

  return (
    <div className={c.coursePlanning}>
      <Planning />
      <h1>2024 Course planning and enrolment pilot</h1>
      {coursePlan ? <CoursePlanPreview coursePlan={coursePlan} /> : null}
      {isEnrichingPlan ? (
        <Loading
          isLoading={isEnrichingPlan}
          loadingMsg="Loading course plan"
          loadingPage={
            <div className={c.loadingScreen}>
              <LoadingIndicator />
              <p>Loading your course plan</p>
            </div>
          }
        />
      ) : null}

      <div className={c.intro}>
        <p>
          Get ready for 2024 with AI-powered course planning and enrolment –
          it’s for re-enrolling students, like you, who began studying a
          Bachelor of Business in 2023.
        </p>
        <p>
          You’ll be able to plan the units you’d like to study for the remainder
          of your degree – feel free to experiment with different majors,
          minors, units and electives. And don’t worry, if you select a unit (or
          area of study) that doesn’t meet the unit rules or course
          requirements, you’ll get instant feedback telling you how to fix it.
        </p>
        <ExternalLink
          type="primary"
          link={url}
          text={coursePlan ? 'Create a new plan' : 'Start planning'}
          loading={!url}
        />
      </div>
    </div>
  );
};

export default CoursePlanning;
