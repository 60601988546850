import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  MegaWrapper,
  AuthProvider,
  ImpersonationWrapper,
  APIProvider,
  MenuProvider,
  accessControl,
  PilotWrapper,
  LoadingScreen,
} from '@monash/portal-frontend-common';
import { Router } from '@monash/portal-react';
import StudentProvider from './components/providers/StudentProvider';
import homeLoading from './assets/lotties/home-loading.json';
import { FEATURES } from './constants/features';

const container = document.getElementById('root');
const root = createRoot(container);

const loadingScreen = (
  <LoadingScreen
    animation={homeLoading}
    message={[
      'Establishing secure connection...',
      'Loading your profile details...',
      'Retrieving your course information...',
      'Verifying credentials... almost there!',
    ]}
  />
);
const wrappers = [
  {
    comp: AuthProvider,
    props: {
      nolanding: true,
      loadingPage: loadingScreen,
      accessControl: accessControl.isStudent,
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : sessionStorage.getItem('fakeData'),
    },
  },
  React.StrictMode,
  {
    comp: APIProvider,
    props: {
      useMocks: FEATURES.FORCE_MOCK_API
        ? process.env.REACT_APP_ENV !== 'prod'
        : sessionStorage.getItem('fakeData'),
    },
  },
  {
    comp: PilotWrapper,
    props: {
      loadingPage: loadingScreen,
    },
  },
  ImpersonationWrapper,
  StudentProvider,
  MenuProvider,
  Router,
  // {comp: ErrorPageWrapper, props: {

  // }
];

root.render(
  <MegaWrapper providers={wrappers}>
    <App />
  </MegaWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
