import React from 'react';
import c from './section.module.scss';

const Section = ({ title, children, sticky = true }) => {
  return (
    <section
      aria-label={title}
      className={`${c.section} ${sticky ? c.sticky : ''}`}
    >
      <h2>{title}</h2>
      {children}
    </section>
  );
};

export default Section;
