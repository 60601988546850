import { Icon, ExternalLink } from '@monash/portal-react';
import React, { useContext } from 'react';
import Card from '../../../ui/card/Card';
import InfoItem from '../info-item/InfoItem';
import { StudentContext } from '../../../providers/StudentProvider';
import { formatAmount } from '../../../utilities/functions/format-amount';
import c from './m-pass.module.scss';

const MPass = () => {
  const { profileData } = useContext(StudentContext);

  const MPassError = profileData?.errors?.find(
    (error) =>
      error.additionalDetails === 'Cardholder not found' ||
      'Unable to reach Transact'
  );

  const infoList = [
    {
      name: 'Account balance',
      icon: Icon.CurrencyDollar,
      data: formatAmount(profileData?.cardholder?.generalBalance),
      error: MPassError,
      action: (
        <ExternalLink
          text="Top up"
          type="text"
          link="https://monash-sp.transactcampus.com/eAccounts/AnonymousHome.aspx"
          className={c.noWrap}
        />
      ),
    },
    {
      name: 'Print balance',
      icon: Icon.Printer,
      error: MPassError,
      data: formatAmount(profileData?.cardholder?.printingBalance, {
        hideEmptyValues: true,
      }),
    },
  ];

  return (
    <Card>
      {infoList.map(
        (item, i) =>
          item.data && (
            <InfoItem
              name={item.name}
              error={item.error}
              icon={item.icon}
              form={item.form}
              displayData={item.data}
              key={i}
              action={item.action}
            />
          )
      )}
    </Card>
  );
};

export default MPass;
