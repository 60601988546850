import c from './credit-progress.module.scss';
import ProgressBar from './progress-bar/ProgressBar';

// Dummy progress object for testing total credits exceeded required credits
// eslint-disable-next-line no-unused-vars
const DUMMY_PROGRESS_DATA = {
  creditPointsRequired: '78',
  creditPointsPassed: '30',
  creditPointsAdvancedStanding: '24',
  creditPointsCurrentEnrolled: '42',
  creditPointsFutureEnrolled: '0',
  creditPointsTotalAchieved: '54',
  percentCompleted: '56.25',
  weightedAverageMark: '68.6',
};

const CreditProgress = ({ progress }) => {
  return (
    <div className={c.creditProgress}>
      <label>Course progress (completed/total required credit points)</label>
      <p>
        {progress.percentCompleted}% ({progress.creditPointsTotalAchieved}/
        {progress.creditPointsRequired} CP)
      </p>
      <ProgressBar progress={progress} />
      <p className={c.disclaimer}>
        Disclaimer: Before you can graduate, you need to gain all the necessary
        credit points and meet any other faculty course requirements.
      </p>
    </div>
  );
};

export default CreditProgress;
