import unitStatusProps from '../data/unit-status-props.json';
import creditGrantingStatusProps from '../data/credit-granting-status-props.json';

/**
 * getBlockStatusProps
 * @param {object} block - block data object
 * @returns {undefined|object} - mapped status props
 */
export const getBlockStatusProps = (block = { blockType: '' }) => {
  let props;

  switch (block.blockType) {
    case 'UNIT':
      props = unitStatusProps[block.status];
      break;
    case 'CREDIT':
      props = creditGrantingStatusProps[block.category][block.grantingStatus];
      break;
    default:
  }

  return props;
};
