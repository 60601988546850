import Section from '../../../../ui/section/Section';
import Period from '../period/Period';
import c from './year.module.scss';

const Year = ({ year, listView, earliestToLatest, openGuide, sticky }) => {
  const sortedPeriods = earliestToLatest
    ? year.periods
    : [...year.periods].reverse();

  return (
    <Section title={year.name} sticky={sticky}>
      <ul className={c.periods}>
        {sortedPeriods.map((period, i) => (
          <Period
            period={period}
            listView={listView}
            key={i}
            openGuide={openGuide}
          />
        ))}
      </ul>
    </Section>
  );
};

export default Year;
