import React from 'react';
import Section from '../../../ui/section/Section';
import c from './course-header.module.scss';
import CourseSelector from './course-selector/CourseSelector';

const CourseHeader = ({
  selectedCourse,
  setSelectedCourse,
  enrolments,
  loadingEnrolments,
}) => {
  return (
    <Section title="Course" titleAlignCenter={true}>
      <div className={c.courseHeader}>
        <div className={c.header}>
          {/* Single course enrolment record */}
          {!loadingEnrolments && selectedCourse && enrolments?.length === 1 && (
            <p>
              {`${selectedCourse.code}.${selectedCourse.version} \u2013 ${selectedCourse.title}`}
            </p>
          )}
          {/* Multiple course enrolment records */}
          {!loadingEnrolments && selectedCourse && enrolments?.length > 1 && (
            <CourseSelector
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              enrolments={enrolments}
            />
          )}
        </div>
      </div>
    </Section>
  );
};

export default CourseHeader;
