import React, { useEffect, useMemo, useState } from 'react';
import BarItem from './bar-item/BarItem';
import IntervalMarkers from './interval-markers/IntervalMarkers';
import Legend from './legend/Legend';
import creditGrantingStatusProps from '../../../../../utilities/data/credit-granting-status-props.json';
import unitStatusProps from '../../../../../utilities/data/unit-status-props.json';
import c from './progressBar.module.scss';

const ProgressBar = ({ progress }) => {
  const progressCredits = useMemo(() => {
    return {
      granted: parseInt(progress.creditPointsAdvancedStanding),
      gained: parseInt(progress.creditPointsPassed),
      enrolled:
        parseInt(progress.creditPointsCurrentEnrolled) +
        parseInt(progress.creditPointsFutureEnrolled),
      required: parseInt(progress.creditPointsRequired),
    };
  }, [progress]);

  const [credits, setCredits] = useState(progressCredits);
  const totalCP = credits.granted + credits.gained + credits.enrolled;

  // Display
  const creditsItems = [
    {
      label: 'Credit granted',
      background: `var(${creditGrantingStatusProps.CREDIT.GRANTED.color})`,
      CP: credits.granted,
    },
    {
      label: 'Gained',
      background: `var(${unitStatusProps.COMPLETED.color})`,
      CP: credits.gained,
    },
    {
      label: 'Enrolled',
      background:
        'repeating-linear-gradient(-45deg, #38853C, #38853C 10px, #68A26B 10px, #68A26B 20px)',
      CP: credits.enrolled,
    },
    {
      label: 'Remaining',
      background: `var(--card-bg-color)`,
      CP: credits.required - totalCP,
    },
  ];

  const barMaxCP = Math.max(totalCP, credits.required);
  const barCreditsItems = creditsItems.filter((item) => item.CP > 0);
  const showRequiredCP = barMaxCP > credits.required;

  useEffect(() => {
    setCredits({
      granted: 0,
      completed: 0,
      enrolled: 0,
      required: 0,
    });
    setTimeout(() => {
      setCredits(progressCredits);
    }, 50);
  }, [progressCredits, progress]);

  return (
    <div className={c.progressBar} aria-hidden="true">
      <div className={c.bar}>
        <div className={c.barItems}>
          {barCreditsItems.map((item, i) => (
            <BarItem
              key={i}
              firstItem={i === 0}
              zIndex={barCreditsItems.length - i}
              background={item.background}
              pctAgainstMaxCP={(item.CP / barMaxCP) * 100}
            />
          ))}
        </div>
        {showRequiredCP && (
          <div
            className={c.requiredCPMarker}
            style={{
              left: `${(credits.required / barMaxCP) * 100}%`,
            }}
          />
        )}
        <IntervalMarkers barMaxCP={barMaxCP} />
      </div>
      <Legend
        barCreditsItems={barCreditsItems}
        showRequiredCP={showRequiredCP}
        requiredCP={credits.required}
      />
    </div>
  );
};

export default ProgressBar;
