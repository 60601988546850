import { gradeStatus } from './grades';
import { nanoid } from 'nanoid';
import { decompress } from './compression';

const formatECPayload = (enrolments, uuid) => {
  const enrolled = enrolments.filter(
    (e) => e.status === 'ENROLLED' && e.code === 'B2000'
  );
  if (enrolled.length === 0) return null;
  const selected = enrolled[0];
  // user
  const user = {
    token: uuid,
  };

  // course
  const course = {
    title: selected.title,
    aos: selected.aos,
  };

  // as
  const advancedStandings = selected.advancedStandings?.map((as) => {
    return {
      unitCode: as.name || null,
      creditPoints: as.creditPoints,
      preclusion: as.recognitionType === 'PRECLUSION' ? as.name : '',
      level: as.unitLevel,
    };
  });

  // units
  const enrolment = Object.keys(selected.years)
    .map((year) => {
      return selected.years[year].periods.map((period) => {
        return {
          year,
          name: period.calType,
          units: period.blocks.map((unit) => {
            return {
              unitCode: unit.name,
              creditPoints: unit.creditPoints,
              status: unit.status,
              result: gradeStatus(unit.grade),
              locationCode: unit.locationCode,
            };
          }),
        };
      });
    })
    .reduce((prev, curr) => [...prev, ...curr], []);

  return {
    user,
    course,
    advancedStandings,
    enrolment,
  };
};

const courseProgressify = (ecFormat, enrolments, refData) => {
  const offeringData = decompress(refData.compressed);
  // as
  const advancedStandings = ecFormat.advancedStandings.map((as) => {
    return {
      id: nanoid(),
      blockType: 'CREDIT',
      category: 'CREDIT',
      creditPoints: as.creditPoints,
      unitLevel: as.level,
      name: as.unitCode || '',
    };
  });

  // units

  const findPeriod = (e) =>
    enrolments?.years[e.year]?.periods.filter((p) => p.calType === e.name)[0] ||
    null;

  const blockifyUnit = (unit, period) => {
    const block = {
      id: nanoid(),
      blockType: 'UNIT',
      creditPoints: unit.creditPoints,
      name: unit.unitCode,
      status: unit.status,
      locationCode: unit.locationCode,
    };

    const existing = findPeriod(period);
    const refUnit = existing?.blocks.filter((b) => b.name === unit.unitCode)[0];

    // if unit is complete/enrolled/existing
    if (refUnit) {
      return {
        ...block,
        mark: refUnit.mark,
        grade: refUnit.grade,
        description: refUnit.description,
        unitOfferingId: refUnit.unitOfferingId,
      };
    } else {
      // unit is new
      const offering = offeringData.find(
        (o) =>
          o.UNIT_CODE.toUpperCase() === unit.unitCode.toUpperCase() &&
          o.LOCATION.toUpperCase() === unit.locationCode.toUpperCase() &&
          o.TEACHING_PERIOD.toUpperCase() === period.name.toUpperCase()
      );
      if (offering) {
        return {
          ...block,
          description: offering.UNIT_TITLE,
          // API fields
          unitEnrolment: {
            unitCode: unit.unitCode,
            calType: period.name,
            locationCode: unit.locationCode.toUpperCase(),
            unitVersionNumber: parseInt(offering.CALLISTA_UNIT_VERSION) || 0,
            ciSequenceNumber: parseInt(offering.CI_SEQUENCE_NUMBER) || 0,
            unitClass: offering.MODE_UNIT_CLASS.toUpperCase(),
          },
        };
      }
    }
    return block;
  };

  const blockifyPeriod = (period, i) => {
    const refPeriod = findPeriod(period);
    const semNames = {
      'S1-01': 'Sem 1 (official calendar)',
      'S2-01': 'Sem 2 (official calendar)',
    };
    return {
      id: nanoid(),
      calType: period.name,
      name: semNames[period.name] || refPeriod?.name || period.name,
      startDate: i,
      blocks: period.units.map((u) => blockifyUnit(u, period)),
    };
  };

  const years = {};

  ecFormat.enrolment.forEach((e, i) => {
    if (years[e.year]) {
      years[e.year].periods.push(blockifyPeriod(e, i));
    } else {
      years[e.year] = {
        id: nanoid(),
        name: e.year,
        periods: [blockifyPeriod(e, i)],
      };
    }
  });

  const now = new Date();

  return {
    code: 'B2000',
    title: 'Bachelor of Business',
    advancedStandings,
    years,
    lastUpdated: now.toISOString(),
  };
};

export { formatECPayload, courseProgressify };
