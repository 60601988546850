import { strFromU8, compressSync, decompressSync, strToU8 } from 'fflate';

const compress = (data) => {
  const buffer = strToU8(JSON.stringify(data));
  const compressed = compressSync(buffer, { level: 6 });

  const hex = Array.from(compressed)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');

  return hex;
};

const decompress = (hex) => {
  const arrayFromHex = Uint8Array.from(
    hex.match(/.{1,2}/g).map((b) => parseInt(b, 16))
  );
  const decompressedArray = decompressSync(arrayFromHex);
  const stringFromArray = strFromU8(decompressedArray);
  const objectFromString = JSON.parse(stringFromArray);

  return objectFromString;
};

export { compress, decompress };
