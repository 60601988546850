import { VirtuallyHidden } from '@monash/portal-react';
import c from './block-status-tag.module.scss';

const BlockStatusTag = ({ blob = true, status }) => {
  return (
    <div className={c.blockStatusTag}>
      <VirtuallyHidden element="label" text="Unit status:" />
      {blob && (
        <div
          className={c.keyBlob}
          style={{
            backgroundColor: `var(${status.color})`,
          }}
        />
      )}{' '}
      <span
        className={c.status}
        style={{
          color: `var(${status.color})`,
        }}
      >
        {status.label}
      </span>
    </div>
  );
};

export default BlockStatusTag;
