import { useContext } from 'react';
import { VirtuallyHidden } from '@monash/portal-react';
import { getBlockDescription } from '../../../../../utilities/functions/get-block-description';
import { getBlockStatusProps } from '../../../../../utilities/functions/get-block-status-props';
import BlockLabel from '../../block-components/block-label/BlockLabel';
import BlockStatusTag from '../../block-components/block-status-tag/BlockStatusTag';
import BlockCreditPointsTag from '../../block-components/block-credit-points-tag/BlockCreditPointsTag';
import c from './list-block.module.scss';
import BlockUnitCode from '../../block-components/block-unit-code/BlockUnitCode';
import BlockGrade from '../../block-components/block-grade/BlockGrade';
import BlockDescription from '../../block-components/block-description/BlockDescription';
import { MenuContext } from '@monash/portal-frontend-common';

const ListBlock = ({ block, index, openGuide }) => {
  const { size } = useContext(MenuContext);
  const blockStatusProps = getBlockStatusProps(block);
  const description = getBlockDescription(block);
  const firstBlockInAPeriod = index === 0;

  const items = [
    {
      label: 'Unit code',
      data: block.name,
      display: <BlockUnitCode code={block.name} />,
    },
    {
      label: 'Unit name',
      data: description,
      display: <BlockDescription description={description} />,
    },
    {
      label: 'Status',
      data: blockStatusProps,
      display: <BlockStatusTag status={blockStatusProps} />,
      guide: true,
    },
    {
      label: 'Grade',
      data: block.mark,
      display: <BlockGrade mark={block.mark} grade={block.grade} />,
    },
    {
      label: 'Credit points',
      // TODO: clean up credit points data in backend
      data: block.creditPoints || true,
      display: <BlockCreditPointsTag creditPoints={block.creditPoints || 0} />,
    },
  ];

  return (
    <div tabIndex="0" className={`${c.listBlock} ${c[size]}`}>
      <VirtuallyHidden element="label" text="Unit" />
      {items.map((item, i) => {
        return (
          <div
            className={c.item}
            key={i}
            // Using item labels for semantic grid styles
            // TODO: explore other ways that do not depend on label text
            style={{ gridArea: item.label.replace(/\s/g, '') }}
          >
            {firstBlockInAPeriod || size !== 'L' ? (
              <BlockLabel
                label={item.label}
                guide={item.guide}
                openGuide={openGuide}
                firstBlockInAPeriod={firstBlockInAPeriod}
              />
            ) : null}
            {!item.data && '-'}
            {item.data && item.display}
          </div>
        );
      })}
    </div>
  );
};

export default ListBlock;
